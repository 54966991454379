<template>
  <div>
    <div class="navbox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公开景区" name="2"></el-tab-pane>
        <el-tab-pane label="暂停营业" name="3"></el-tab-pane>
        <el-tab-pane label="私有景区" name="0"></el-tab-pane>
        <el-tab-pane label="临时景区" name="-1"></el-tab-pane>
        <el-tab-pane label="导航点" name="10"></el-tab-pane>
        <el-tab-pane label="废弃导航点" name="11"></el-tab-pane>
        <el-tab-pane label="搜索结果" name="-2"></el-tab-pane>
        <el-tab-pane name="-3">
          <span slot="label">
            反馈信息
            <i v-if="countFeedback() > 0" style="color:red">({{countFeedback()}})</i>
          </span>
        </el-tab-pane>
      </el-tabs>
      <div style="padding-bottom:10px;margin-left:20px;width:120px">
        <el-select v-model="typeSelected" placeholder="请选择" size="mini" @change="handleTypeChange">
          <el-option
            v-for="(item,index) in sightTypes"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div style="padding-bottom:10px;margin-left:20px;width:120px">
        <el-select v-model="province" placeholder="请选择" size="mini" @change="handleChange">
          <el-option v-for="(item,id) in provinces" :key="id" :label="item.name" :value="id"></el-option>
        </el-select>
      </div>

      <div style="padding-bottom:10px;margin-left:20px" v-if="cities.length>0">
        <el-select v-model="city" placeholder="请选择" size="mini" @change="handleCityChange">
          <el-option v-for="(item,id) in cities" :key="id" :label="item.name" :value="id"></el-option>
        </el-select>
      </div>
      <div class="list-g" style="padding-bottom:10px;margin-left:20px">
        <el-input type="text" class="inputbox-g" v-model="searchValue" size="mini"></el-input>
        <el-button type="primary" @click="search" size="mini">搜索</el-button>
      </div>
    </div>
    <div style="margin-bottom: 20px;color:mediumseagreen">合计 ： {{ total }}</div>
    <el-table :data="tableData" style="width: 100%" size="small">
      <el-table-column v-for="(value,key) in tableHead" :key="key" :prop="key" :label="value"></el-table-column>
      <el-table-column label="状态" prop="state" v-if="activeName==-2"></el-table-column>
      <el-table-column label="查看">
        <template slot-scope="scope">
          <el-button @click="goSightDetail(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import {
  getAdminSights,
  getSightFeedBack,
  getCityList,
  adminSearchSight,
  adminGetSightTypes
} from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      tableHead: {},
      searchValue: "",
      page: 1,
      count: 30,
      sightTypes: [],
      typeSelected: "全部类别",
      total: null,
      activeName: "2",
      adcode: "100000",
      type: -1,
      name: "",
      provinces: [],
      cities: [],
      province: "全部地区",
      city: "全部城市",
      value: [100000],
      sightHead: {
        id: "ID",
        name: "名称",
        city: "城市",
        district: "区县",
        phone: "电话",
        stars: "评级",
        // adcode: "区码",
        typeName: "类别",
        idSightCompany: "商家ID"
        // ticketSell: "网络售票"
      },
      feedbacksHead: {
        id: "ID",
        sightName: "景点名称",
        text: "内容",
        genTime: "提交时间"
      }
    };
  },
  mounted() {
    this.getAdminSightsFn();
    this.getCityListFn();
    this.getSightType();
  },
  methods: {
    getAdminSightsFn() {
      const that = this;
      getAdminSights({
        page: that.page,
        state: that.activeName,
        count: that.count,
        adcode: that.adcode,
        type: that.type
      }).then(res => {
        if (res.result) {
          that.tableHead = that.sightHead;
          that.tableData = res.data.sights;
          that.total = res.data.total;
        }
      });
    },
    getCityListFn() {
      const that = this;
      getCityList({
        type: "province"
      }).then(res => {
        that.provinces = res.data;
        that.provinces.unshift({
          adcode: 100000,
          name: "全部地区",
          children: []
        });
      });
    },

    getSightType() {
      adminGetSightTypes().then(res => {
        let alltype = {
          name: "全部类别",
          value: -1
        };
        this.sightTypes = res.data.sightTypes;
        this.sightTypes.unshift(alltype);
      });
    },

    handleTypeChange(e) {
      this.type = e;
      this.activeName = 2;
      this.page = 1;
      this.searchValue = "";
      this.getAdminSightsFn();
    },
    search() {
      const that = this;
      adminSearchSight({
        sightName: that.searchValue
      }).then(res => {
        that.tableHead = that.sightHead;
        that.tableData = res.data.sights;
        that.total = res.data.total;
        that.activeName = "-2";
        that.adcode = "100000";
        that.province = "全部地区";
        that.cities = [];
        that.typeSelected = "全部类别";
        that.type = -1;
      });
    },
    goSightDetail(data) {
      let id = "";
      if (this.activeName == -3) {
        id = data.idSight;
      } else {
        id = data.id;
      }
      this.$router.push({
        path: "/sights/sightdetail",
        query: { id: id }
      });
    },
    getFeedBack() {
      const that = this;
      getSightFeedBack({
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.tableData = res.data.feedBacks;
          that.total = res.data.total;
          that.tableHead = that.feedbacksHead;
        }
      });
    },
    handleClick() {
      if (this.activeName > -2) {
        this.searchValue = "";
        this.getAdminSightsFn();
      } else {
        this.getFeedBack();
      }
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getAdminSightsFn();
    },
    countFeedback() {
      return this.$store.getters.getFirstAuth.feedback;
    },

    handleChange(id) {
      let provinceSelected = this.provinces[id];
      this.cities = [];
      this.city = "全部城市";
      this.activeName = "2";
      this.adcode = provinceSelected.adcode;
      this.page = 1;
      this.searchValue = "";
      this.getAdminSightsFn();
      if (provinceSelected.children.length > 1) {
        this.cities = JSON.parse(JSON.stringify(provinceSelected.children));
        this.cities.unshift({
          adcode: provinceSelected.adcode,
          name: "全部城市"
        });
      } else {
        this.cities = [];
      }
    },

    handleCityChange(id) {
      this.adcode = this.cities[id].adcode;
      this.page = 1;
      this.getAdminSightsFn();
    }
  }
};
</script>

<style scoped>
.navbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}
</style>